<template>
  <div>
    <div class="bg-cek-tiket">
      <div class="header">CEK STATUS GO SERVICE</div>
      <div class="form-search">
        <div class="mb-1">
          <div class="input-group input-group-flat">
            <span class="input-group-text" id="basic-addon1">E-Tiket</span>
            <input
              type="text"
              v-model="searchTerm"
              class="form-control"
              placeholder="Masukkan No E-Tiket Anda"
              @keydown.enter="searchData"
            />
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="searchData"
            >
              <div class="btn-dalem">CARI</div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="input">
      <div class="container">
        <div
          class="alert mt-1 alert-warning d-flex align-items-center mt-2"
          role="alert"
        >
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation "
            size="lg"
          />
          <div>
            <span class="text mx-2"> Masukkan No E-Tiket Anda</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-status-tiket">
      <div class="order-track">
        <!-- <div v-if="loading" class="text-center mt-3">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <div>
          <div v-if="showResults">
            <!-- <div v-if="hasResults"> -->
            <div>
              <div class="tracking-order">
                <article class="card card-bg">
                  <header class="card-header">
                    No E-Tiket: {{ item.e_tiket }}
                  </header>
                  <div class="card-body mb-3">
                    <div class="track">
                      <div class="step" :class="{ active: item.status >= 1 }">
                        <span class="icon"
                          ><font-awesome-icon
                            icon="fa-regular fa-rectangle-list"
                          />
                        </span>
                        <span class="text">Di Pesan</span>
                        <p class="text-date">{{ item.date_created }}</p>
                      </div>
                      <div class="step" :class="{ active: item.status >= 2 }">
                        <span class="icon">
                          <font-awesome-icon icon="fa-regular fa-eye" />
                        </span>
                        <span class="text"> Di Tinjau</span>
                      </div>
                      <div class="step" :class="{ active: item.status >= 3 }">
                        <template
                          v-if="
                            item.status == 4 ||
                            (item.status == 4 && item.catatan)
                          "
                        >
                          <span class="icon">
                            <font-awesome-icon
                              icon="fa-solid fa-times-circle"
                            />
                          </span>
                          <span class="text"> Cancel </span>
                          <!-- <p class="text-date">{{ item.catatan }}</p> -->
                        </template>
                        <template
                          v-else-if="
                            item.status <= 3 ||
                            (item.status == 3 && !item.catatan)
                          "
                        >
                          <span class="icon">
                            <font-awesome-icon icon="fa-solid fa-truck" />
                          </span>
                          <span class="text"> Di Terima </span>
                        </template>
                      </div>
                    </div>
                  </div>
                </article>
                <div v-if="item.status == 3">
                  <div
                    class="alert mt-1 alert-info d-flex align-items-center"
                    role="alert"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-triangle-exclamation "
                      size="lg"
                      style="margin-right: 10px"
                    />
                    <div>
                      <span class="text"
                        >Untuk proses penjemputan barang, Nanti akan dihubungi
                        oleh admin</span
                      >
                    </div>
                  </div>
                </div>
                <div v-if="item.status == 4 && item.catatan">
                  <div
                    class="alert mt-1 alert-danger d-flex align-items-center"
                    role="alert"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-triangle-exclamation "
                      size="lg"
                      style="margin-right: 10px"
                    />
                    <div>
                      <span class="text">{{ item.catatan }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="not">
            <div
              class="alert mt-1 alert-danger d-flex align-items-center"
              role="alert"
            >
              <font-awesome-icon
                icon="fa-solid fa-triangle-exclamation "
                size="lg"
                style="margin-right: 10px"
              />
              <div>
                <span class="text">Data tidak ditemukan</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "CekGoComponent",
  data() {
    return {
      searchTerm: "",
      showResults: false,
      // loading: false,
      input: false,
      not: false,
    };
  },

  watch: {
    searchTerm(newValue) {
      if (!newValue) {
        this.clearResults();
      }
    },
  },
  methods: {
    async searchData() {
      if (!this.searchTerm) {
        this.input = true;
        setTimeout(() => {
          this.input = false;
        }, 3000);
        return;
      }
      // this.loading = true;
      try {
        const response = await api.get(
          `Content/checkGoService/?etiket=${this.searchTerm}`
        );
        if (response.data["status"] == 200) {
          this.item = response.data["data"];
          this.showResults = true;
          this.not = false;
        } else {
          this.not = true;
          this.showResults = false;
          this.item = "";
        }
      } catch (error) {
        // console.error(error);
      }
    },
    clearResults() {
      setTimeout(() => {
        this.showResults = false;
        this.not = false;
      }, 2000);
    },
  },
};
</script>

<style></style>
