import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import ServiceView from "../views/Go-serviceView.vue";
import LokasiView from "../views/LokasiView.vue";
import NotFoundView from "../views/NotFoundView.vue";
// import TestimonyView from "../views/TestimonyView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/go-service",
    name: "service",
    component: ServiceView,
  },
  {
    path: "/lokasi",
    name: "lokasi",
    component: LokasiView,
  },
  {
    path: "*",
    name: "not",
    component: NotFoundView, 
  },
  // {
  //   path: "/testimony",
  //   name: "Testimony",
  //   component: TestimonyView, 
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
