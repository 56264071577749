<template>
  <div class="go-service">
    <section class="hero-go-service">
      <div class="container">
        <div class="bg-hero-service">
          <div class="row">
            <div class="col-md-6 col-img-go">
              <img :src="`${BASE_URL}icon/img-go-service.png`" alt="" />
            </div>
            <div class="col-md-6 col-go-service">
              <div class="content">
                <p class="header">GO SERVICE</p>
                <p class="text-service1">
                  Jika Anda membutuhkan perbaikan handphone tapi tidak memiliki waktu untuk datang ke toko kami, jangan khawatir. Kami menyediakan layanan jemput gratis untuk memudahkan Anda. Cukup isi formulir yang tersedia di bawah secara lengkap dan benar agar kami dapat memprosesnya.
                </p>
                <p class="text-service2">Jangan biarkan masalah handphone Anda mengganggu aktivitas harian Anda. Kami siap membantu mengatasinya dengan cepat dan tepat</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="formulir">
      <div class="container">
        <div class="row row-form">
          <div class="col-md-5">
            <form-service-component />
          </div>
          <div class="col-md-7">
            <cek-go-component />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FormServiceComponent from "@/components/FormServiceComponent.vue";
import CekGoComponent from "@/components/CekGoComponent.vue";
import { BASE_URL } from "@/config";
// @ is an alias to /src

export default {
  name: "ServiceView",
  components: {
    FormServiceComponent,
    CekGoComponent,
  },
  data() {
    return {
      BASE_URL,
    };
  },
};
</script>
