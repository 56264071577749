<template>
  <footer class="mt-auto">
    <section class="footer">
      <div class="container">
        <div class="medsos" v-if="sosmed">
          <a
            v-b-tooltip.hover="'Facebook '"
            v-if="sosmed.facebook"
            :href="sosmed.facebook"
            target="_blank"
            ><img :src="`${BASE_URL}icon/fb.png`" alt=""
          /></a>
          <a
            v-b-tooltip.hover="'Instagram '"
            v-if="sosmed.instagram"
            :href="sosmed.instagram"
            target="_blank"
            ><img :src="`${BASE_URL}icon/ig.png`" alt=""
          /></a>
          <a
            v-b-tooltip.hover="'Whatsapp '"
            v-if="sosmed.whatsapp"
            :href="`https://api.whatsapp.com/send?phone=62${sosmed.whatsapp}`"
            target="_blank"
            ><img :src="`${BASE_URL}icon/wa.png`" alt=""
          /></a>
        </div>
        <div class="text">
          <span>CV. MULTI GRAHA RADHIKA © 2023. All Rights Reserved.</span>
        </div>
      </div>
    </section>
  </footer>
</template>
<script>
import { BASE_URL } from "@/config";
import api from "@/api";
export default {
  name: "FooterComponent",
  data() {
    return {
      BASE_URL,
      sosmed: null,
    };
  },
  async mounted() {
    try {
      const response = await api.get("Content/sosmed");
      this.sosmed = response.data.data[0];
    } catch (error) {
      // console.error(error);
    }
  },
};
</script>

<style></style>
