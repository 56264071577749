<template>
  <section class="store">
    <div class="container">
      <div class="content-store">
        <div class="header">STORE GADGETCARE</div>
        <carousel
          v-if="locations.length > 0"
          :responsive="{
            0: { items: 1, nav: false },
            768: { items: 3, nav: true },
            991: { items: 4, nav: true },
          }"
          :autoplay="true"
          :nav="false"
          :margin="10"
          :dots="false"
          :loop="true"
        >
          <div class="item" v-for="location in locations" :key="location.id">
            <router-link to="/lokasi">
              <div class="card-store">
                <div class="d-flex justify-content-center">
                  <img :src="`${BASE_URL}loading/logo.png`" alt="" />
                </div>
                <p class="wilayah-store">{{ location.name_store }}</p>
                <p class="alamat-store">{{ location.alamat_store }}</p>
                <p class="no-store">
                  <font-awesome-icon icon="fa-brands fa-whatsapp" /> 0{{
                    location.whatsapp
                  }}
                </p>
              </div>
            </router-link>
          </div>
        </carousel>
      </div>
    </div>
  </section>
</template>
<script>
import { BASE_URL } from "@/config.js";
import api from "@/api";
import carousel from "vue-owl-carousel";

export default {
  name: "StoreComponent",
  components: {
    carousel,
  },
  data() {
    return {
      locations: [],
      BASE_URL,
    };
  },
  async mounted() {
    try {
      const response = await api.get("Content/toko");
      this.locations = response.data["data"];
    } catch (error) {
      // console.error(error);
    }
  },
};
</script>

<style></style>
