<template>
  <div>
    <section class="bg-service-cek">
      <div class="container">
        <div class="content-service-cek">
          <div class="row d-flex align-items-center">
            <div class="col-md-6">
              <p class="header">CEK INVOICE SERVICE DAN GARANSI</p>
            </div>
            <div class="col-md-6">
              <div class="form-search">
                <div class="mb-1">
                  <div class="input-group input-group-flat">
                    <input
                      type="text"
                      v-model="invoiceNumber"
                      class="form-control"
                      placeholder="Masukkan No Invoice Anda"
                      @keydown.enter="searchInvoice"
                    />
                    <button
                      class="btn btn-outline-secondary"
                      @click="searchInvoice"
                      type="submit"
                    >
                      <div class="btn-dalem">CARI</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="input">
      <div class="container">
        <div
          class="alert mt-1 alert-warning d-flex align-items-center mt-2"
          role="alert"
        >
          <font-awesome-icon
            icon="fa-solid fa-triangle-exclamation "
            size="lg"
          />
          <div>
            <span class="text mx-2"> Masukkan No Invoice</span>
          </div>
        </div>
      </div>
    </div>
    <section class="tracking-order">
      <div class="container">
        <!-- <div v-if="loading" class="text-center mt-3">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->

        <div>
          <div v-if="showResults">
            <article class="card card-bg">
              <header class="card-header">
                No Pesanan: {{ invoice.invoice_serv }}
              </header>
              <div class="card-body">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md col-6">
                        <p class="title-track">Nama:</p>
                        <p class="desc-track" v-if="invoice.name == null">
                          {{ invoice.nm_konsumen }}
                        </p>
                        <p class="desc-track" v-else>{{ invoice.name }}</p>
                      </div>
                      <div class="col-md col-6">
                        <p class="title-track">Model:</p>
                        <p class="desc-track">{{ invoice.merek_hp }}</p>
                      </div>
                      <div class="col-md col-6">
                        <p class="title-track">Imei:</p>
                        <p class="desc-track">
                          {{ invoice.imei_1 }} / {{ invoice.imei_2 }}
                        </p>
                      </div>
                      <div class="col-md col-6">
                        <p class="title-track">Kerusakan:</p>
                        <p class="desc-track">{{ invoice.kerusakan }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="track">
                  <div class="step" :class="{ active: invoice.id_proses >= 1 }">
                    <span class="icon">
                      <font-awesome-icon icon="fa-solid fa-list-ol" />
                    </span>
                    <span class="text">Antri</span>
                  </div>
                  <div class="step" :class="{ active: invoice.id_proses >= 2 }">
                    <span class="icon">
                      <font-awesome-icon icon="fa-solid fa-arrows-spin"
                    /></span>
                    <span class="text"> Proses</span>
                  </div>
                  <div class="step" v-if="invoice.id_proses <= 2">
                    <span class="icon">
                      <font-awesome-icon icon="fa-solid fa-circle-check" />
                    </span>
                    <span class="text">Selesai</span>
                  </div>
                  <div
                    class="step"
                    v-if="invoice.id_proses >= 3"
                    :class="{ active: invoice.id_proses >= 3 }"
                  >
                    <template
                      v-if="
                        invoice.id_proses == 4 ||
                        (invoice.id_proses >= 5 && invoice.catatan)
                      "
                    >
                      <span class="icon">
                        <font-awesome-icon icon="fa-solid fa-times-circle" />
                      </span>
                      <span class="text"> Cancel </span>
                    </template>
                    <template
                      v-else-if="
                        invoice.id_proses == 3 ||
                        (invoice.id_proses >= 5 && !invoice.catatan)
                      "
                    >
                      <span class="icon">
                        <font-awesome-icon icon="fa-regular fa-circle-check" />
                      </span>
                      <span class="text"> Selesai </span>
                    </template>
                  </div>
                  <div class="step" :class="{ active: invoice.id_proses >= 5 }">
                    <span class="icon"
                      ><font-awesome-icon
                        icon="fa-solid fa-arrow-up-right-from-square"
                    /></span>
                    <span class="text">Diambil</span>
                  </div>
                </div>
              </div>
            </article>

            <div v-if="invoice.id_proses == 2">
              <div
                v-if="invoice.notes != null"
                class="alert mt-1 alert-info d-flex align-items-center mt-2"
                role="alert"
              >
                <font-awesome-icon
                  :icon="['fas', 'circle-info']"
                  fade
                  size="lg"
                />
                <div>
                  <span class="text mx-2"> {{ invoice.notes }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="
                invoice.id_proses == 4 ||
                (invoice.id_proses >= 5 && invoice.catatan)
              "
            >
              <div
                class="alert mt-1 alert-danger d-flex align-items-center mt-2"
                role="alert"
              >
                <font-awesome-icon
                  :icon="['fas', 'circle-info']"
                  fade
                  size="lg"
                />
                <div>
                  <span class="text mx-2"> {{ invoice.catatan }}</span>
                </div>
              </div>
            </div>
            <article class="card card-bg" v-if="invoice.id_proses >= 5">
              <header class="card-header">Status Garansi:</header>
              <div class="card-body">
                <span v-if="garansi !== null"
                  ><p class="fw-bold">Masa Berlaku Hingga</p>
                  {{ garansi.tgl_akhir }}</span
                >
                <span v-else>TANPA GARANSI</span>
              </div>
            </article>
          </div>
          <article class="card card-bg" v-if="garansiPart">
            <header class="card-header">Status Garansi:</header>
            <div class="card-body">
              <span v-if="garansiPart !== null"
                ><p class="fw-bold">Masa Berlaku Hingga</p>
                {{ garansiPart.tgl_akhir }}</span
              >
              <span v-else>TANPA GARANSI</span>
            </div>
          </article>
          <div v-if="not">
            <div
              class="alert mt-1 alert-danger d-flex align-items-center mt-2"
              role="alert"
            >
              <font-awesome-icon
                icon="fa-solid fa-triangle-exclamation "
                size="lg"
              />
              <div>
                <span class="text mx-2"> {{ not }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "@/api";
export default {
  name: "CekServiceComponent",
  data() {
    return {
      showResults: false,
      // loading: false,
      input: false,
      invoiceNumber: "",
      not: "",
      name: "",
      garansiPart: "",
    };
  },
  mounted() {
    const params = new URLSearchParams(this.$route.query);
    const noInvoice = params.get("no_invoice");
    if (noInvoice) {
      this.invoiceNumber = noInvoice;
      this.searchInvoice();
    }
  },
  watch: {
    invoiceNumber(newValue) {
      if (!newValue) {
        this.clearResults();
      }
    },
  },

  methods: {
    async searchInvoice() {
      if (!this.invoiceNumber) {
        this.input = true;
        setTimeout(() => {
          this.input = false;
        }, 3000);
        return;
      }
      // this.loading = true;
      try {
        const response = await api.get(
          `Content/invoice?invoice=${this.invoiceNumber}`
        );
        if (response.data["status"] == 200) {
          if (response.data["data"][0] !== null) {
            this.showResults = true;
            this.invoice = response.data["data"][0];
            this.garansi = response.data["data"][1];
          }
          if (response.data["data"][0] == null) {
            this.garansiPart = response.data["data"][1];
          }
          this.not = "";
          console.log(response.data["data"]);
        } else {
          this.not = response.data["message"];
          this.showResults = false;
          this.garansiPart = false;
          this.invoice = "";
        }
      } catch (error) {
        // console.error(error);
      }
    },
    clearResults() {
      setTimeout(() => {
        this.showResults = false;
        this.not = false;
        this.garansiPart = false;
      }, 2000);
    },
  },
};
</script>
<style></style>
