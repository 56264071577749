// store.js
import Vue from "vue";
import Vuex from "vuex";
import api from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locations: [],
  },
  mutations: {
    setLocations(state, data) {
      state.locations = data;
    },
  },
  actions: {
    async fetchLocations({ commit, state }) {
      if (state.locations.length > 0) {
        return state.locations;
      }

      try {
        const response = await api.get("Content/toko");
        const data = response.data["data"];
        commit("setLocations", data);

        return data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
});
