<template>
  <section class="layanan">
    <div class="container">
      <div class="content-layanan">
        <div class="header">LAYANAN GADGETCARE</div>
        <div class="card-content">
          <div class="row">
            <div class="col-md-3 col-6">
              <div class="card-layanan">
                <img :src="`${BASE_URL}icon/icon-cs.png`" alt="" />
                <p class="header-card">Konsultasi Gratis</p>
                <p class="desc-card">Gratis pengecekan dan konsultasi untuk gadget anda di Toko Kami.</p>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="card-layanan">
                <img :src="`${BASE_URL}icon/icon-go-service.png`" alt="" />
                <p class="header-card">Go Service</p>
                <p class="desc-card">Kami siap menjemput gadget Anda untuk diperbaiki.</p>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="card-layanan">
                <img :src="`${BASE_URL}icon/icon-garansi.png`" alt="" />
                <p class="header-card">Berkualitas dan Garansi</p>
                <p class="desc-card">Kualitas terbaik dengan garansi yang kami tawarkan.</p>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="card-layanan">
                <img :src="`${BASE_URL}icon/icon-harga.png`" alt="" />
                <p class="header-card">Harga Terjangkau</p>
                <p class="desc-card">Harga terjangkau untuk layanan gadget berkualitas.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { BASE_URL } from "@/config";
export default {
  name: "LayananComponent",
  data() {
    return {
      BASE_URL,
    };
  },
};
</script>

<style></style>
